@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  background-color: #0f0e0f;
}

.custom-cursor {
  cursor: url("./assets/Cursor.svg"), auto;
}

.inner-shadow {
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.08);
}

.tight-fit {
  line-height: 1;
  display: inline-block;
}

/* Importing DM Sans from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700;800&display=swap");

/* Clash Display Font Faces */
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("./assets/fonts/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Geist Font Faces */
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/Geist-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.map-container {
  background-color: #1A1A1A;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.map-container .leaflet-container {
  border-radius: 10px;
}
